
























































































































import { Vue, Component, Inject } from "vue-property-decorator";
import { Observer } from "mobx-vue";
import { walletStore } from "@/stores/wallet-store";
import { TicketViewModel } from "../viewmodels/ticket-viewmodel";

@Observer
@Component
export default class IncreaseStakeDialog extends Vue {
    @Inject() vm!: TicketViewModel;
    walletStore = walletStore;

    getLCEO() {
        window.open(`https://pancakeswap.finance/swap?outputCurrency=${process.env.VUE_APP_TOKEN_ADDRESS}`, "_blank");
    }
}
